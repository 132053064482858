@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-icon_soc_linkedin:before,
.icon-icon_soc_twitter:before,
.icon-localisation:before,
.icon-message:before,
.icon-icon_soc_facebook:before,
.icon-arrowleft:before,
.icon-arrowright:before,
.icon-arrowup:before,
.icon-properties:before,
.icon-people:before,
.icon-key:before,
.icon-moon:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-icon_soc_linkedin:before { content: "\EA04" }
.icon-icon_soc_twitter:before { content: "\EA05" }
.icon-localisation:before { content: "\EA08" }
.icon-message:before { content: "\EA09" }
.icon-icon_soc_facebook:before { content: "\EA0E" }
.icon-arrowleft:before { content: "\EA0F" }
.icon-arrowright:before { content: "\EA10" }
.icon-arrowup:before { content: "\EA11" }
.icon-properties:before { content: "\EA13" }
.icon-people:before { content: "\EA14" }
.icon-key:before { content: "\EA15" }
.icon-moon:before { content: "\EA16" }
